import DataDictionaryService from './api/data-dictionary.service';
import { DataDictionaryEntryInput } from './interfaces/data-dictionary-input.interface';
import { useConquestMutator } from '../../hooks/use-conquest-mutator';
import { useConquestQuery } from '../../hooks/use-conquest-query';

const dataDictionaryService = new DataDictionaryService();
const getAuthoredDataDictionaryEntries = 'getAuthoredDataDictionaryEntries';

export const useGetDataDictionaryEntries = () => {
    return useConquestQuery(async (page: number, limit: number) => {
        const { data } = await dataDictionaryService.getDataDictionaryEntries(page, limit);

        return data?.getAllDataDictionaryEntriesContent;
    }, getAuthoredDataDictionaryEntries);
};

export const useGetDataDictionaryEntry = () => {
    return useConquestQuery(async (entryId: number) => {
        const { data } = await dataDictionaryService.getDataDictionaryEntry(entryId);

        return data?.getDataDictionaryEntry;
    }, getAuthoredDataDictionaryEntries);
};

export const useRemoveDataDictionaryEntry = () => {
    return useConquestMutator(async (entry: DataDictionaryEntryInput) => {
        const { data } = await dataDictionaryService.deleteDataDictionaryEntry(entry);

        return data?.deleteDataDictionaryEntryContent;
    });
};

export const useUpdateDataDictionaryEntry = () => {
    return useConquestMutator(async (entry: DataDictionaryEntryInput) => {
        const { data } = await dataDictionaryService.updateDataDictionaryEntry(entry);

        return data?.updateDataDictionaryEntryContent;
    });
};

export const useGetDataDictionaryEntriesByCategory = () => {
    return useConquestQuery(async (category: string, page: number, limit: number) => {
        const { data } = await dataDictionaryService.getDataDictionaryEntriesByCategory(category, page, limit);

        return data?.getDataDictionaryEntriesByCategoryContent;
    }, getAuthoredDataDictionaryEntries);
};
