import { FormControl } from '@chakra-ui/react';
import * as React from 'react';
import { useQuery } from 'react-query';

import Dropdown from '../../base/dropdown/dropdown';
import { BaseSelector } from '../../base/interfaces/base-selector';
import { useGetDataDictionaryEntriesByCategory } from '../../data-dictionary/data-dictionary.service.helper';

const TagFilterTypeSelector: React.FC<BaseSelector> = ({ helperText, onSelectionChange, placeholder, selectedId }) => {
    const { data: tagTypes } = useQuery(useGetDataDictionaryEntriesByCategory()(['tag-type', 1, 9999999]));
    const tagTypeOptions = tagTypes?.entries.map((tag) => ({
        label: tag.value,
        id: tag.id
    }));

    return (
        <FormControl>
            {tagTypeOptions && (
                <Dropdown helperText={helperText} options={tagTypeOptions} value={selectedId} placeholder={placeholder} onChange={onSelectionChange} variant={'flushed'} />
            )}
        </FormControl>
    );
};

export default TagFilterTypeSelector;
