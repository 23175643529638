import { gql } from '@apollo/client';

export class DataDictionaryCreateQueries {
    static readonly createDataDictionaryEntry = gql`
        mutation createDataDictionaryEntryContent($entry: DataDictionaryEntryInput!) {
            createDataDictionaryEntryContent(entry: $entry) {
                category
                value
                sortOrder
                id
            }
        }
    `;
}
