import { Content, DropdownSegment } from '@3plearning/question-components-library';
import { UseToastOptions } from '@chakra-ui/react';
import { JSONContent } from '@tiptap/react';
import fastHashCode from 'fast-hash-code';

import { KeyMapping, NestedArray, ObjectWithKey } from './interfaces/types';
import { MultiWorkedSolutions } from '../components/questions/components/props/question.props';

class Utility {
    static getPrimaryItem<T extends { isPrimary: boolean }>(input: T[]): T {
        if (!input || !input.length) return {} as T;
        const primaryItem = input.find((item) => item.isPrimary);

        if (primaryItem) return primaryItem as T;

        return input[0] as T;
    }

    static getPrimaryItems<T extends { isPrimary: boolean }>(input: Map<string, T[]>): Map<string, T> {
        const primaryItems = new Map<string, T>();

        input.forEach((value, key) => {
            primaryItems.set(key, Utility.getPrimaryItem<T>(value as T[]) as T);
        });

        return primaryItems;
    }

    static createValidationResponseObject(err: string): UseToastOptions {
        return {
            position: 'bottom',
            title: 'Validation Failed',
            description: err,
            status: 'error',
            duration: 5000,
            isClosable: true
        };
    }

    static hashUtc(): number {
        const d = new Date();
        const str = d.toUTCString();

        return fastHashCode(str, { forcePositive: true }) + Math.floor(Math.random() * 1000);
    }

    static convertArrToStr(inputArr?: string[], separator = ','): string {
        let str = '';

        if (inputArr) {
            str = inputArr.join(separator);
        }

        return str;
    }

    static convertStrToArr(inputStr: string, separator = ','): string[] {
        if (inputStr.length <= 0) {
            return [];
        }

        return inputStr.replaceAll(' ', '').split(separator);
    }

    static getIds<T extends { id: string }>(input: T[]): string[] {
        return input.map((item: T) => item.id);
    }

    static updateSortOrderOnDragDrop<T extends { sortOrder: number; id: string }>(items: T[], idField: string) {
        return items.map((item, index) => ({
            [idField]: item.id,
            sortOrder: index + 1
        }));
    }

    static updateSortOrder<T extends { sortOrder: number; id: string }>(idField: string, removedSortOrderId: number, input: T[]) {
        return input.map((item) => ({
            [idField]: item.id,
            sortOrder: item.sortOrder > removedSortOrderId ? item.sortOrder - 1 : item.sortOrder
        }));
    }

    static arrangeSortOrderByAscending<T extends { sortOrder: number }>(items: T[]) {
        const sortedItems = [...items];

        sortedItems.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));

        return sortedItems;
    }

    static generateDropdownOptions(options: Record<string, number>) {
        return Object.entries(options).map(([key, value]) => ({
            id: `${value}`,
            label: key
        }));
    }

    static generateDropdownStrOptions(options: Record<string, string>) {
        return Object.entries(options).map(([key, value]) => ({
            id: key,
            label: value
        }));
    }

    static getSortOrder(itemLength: number) {
        return itemLength ? itemLength + 1 : 1;
    }

    static stringifyWithIndentation(value: JSONContent | MultiWorkedSolutions) {
        return JSON.stringify(value, undefined, 4);
    }

    static updateKeys(arr: NestedArray, keyMapping: KeyMapping): Array<ObjectWithKey | Array<ObjectWithKey>> {
        return arr.map((obj) => {
            const newObj: ObjectWithKey = {};

            for (const [oldKey, value] of Object.entries(obj)) {
                const newKey = keyMapping[oldKey] || oldKey;

                if (Array.isArray(value)) {
                    newObj[newKey] = this.updateKeys(value, keyMapping);
                } else if (typeof value === 'object') {
                    // eslint-disable-next-line prefer-destructuring
                    newObj[newKey] = this.updateKeys([value], keyMapping)[0];
                } else {
                    newObj[newKey] = value;
                }
            }

            return newObj;
        });
    }

    static addZIndexToDropdownItems(question: JSONContent[]): Content {
        question?.forEach((questionContentItem) => {
            if (questionContentItem.type === 'dropdown') {
                (questionContentItem.attrs as DropdownSegment['attrs']).zIndex = 1500;
            }

            if (Array.isArray(questionContentItem.content)) {
                Utility.addZIndexToDropdownItems(questionContentItem.content);
            }
        });

        return question as Content;
    }

    static createQuestionBlocks(content: JSONContent[] | string | undefined) {
        return typeof content !== 'string' && content
            ? content
            : [
                  {
                      type: 'paragraph',
                      content: []
                  }
              ];
    }

    static adjustBodyPadding(this: void) {
        document.body.style.paddingBottom = `${window.mathVirtualKeyboard.boundingRect.height}px`;
    }

    static setKeypadContainer() {
        const keypadContainer = document.querySelector('.mathlive-native-ui') || document.createElement('div');

        keypadContainer.className = 'mathlive-native-ui';
        document.body.appendChild(keypadContainer);
        window.mathVirtualKeyboard.container = keypadContainer as HTMLElement;
        window.mathVirtualKeyboard.removeEventListener('geometrychange', Utility.adjustBodyPadding);
        window.mathVirtualKeyboard.addEventListener('geometrychange', Utility.adjustBodyPadding);
    }
}

export default Utility;
