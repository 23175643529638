import { UseToastOptions } from '@chakra-ui/react';

const ServiceErrorMessage = (error: string): UseToastOptions => ({
    position: 'bottom',
    title: 'Service Error',
    description: error,
    status: 'error',
    duration: 5000,
    isClosable: true
});

export default ServiceErrorMessage;
