import { useToast } from '@chakra-ui/react';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetStrandById, useUpdateStrand } from './api/strand.service.helper';
import StrandForm from './strand.form';
import validationSchema from './validation/strand.create.validation';
import { baseStrandUrl } from '../../common/constants';
import ServiceErrorMessage from '../../common/toast-messages/service-error-message';
import serviceSuccessMessage from '../../common/toast-messages/service-success-message';
import utility from '../../common/utility';
import LoadingWrapper from '../base/loading-wrapper';
import { TreeSectionChildNodeDto } from '../curriculum/interface';

const StrandEdit = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const { id } = useParams() as { id: string };
    const { data: strand, isLoading: isLoadingStrand, refetch: refetchStrand } = useQuery(useGetStrandById()([id]));
    const { mutate: updateStrand } = useMutation(useUpdateStrand(), {
        onSuccess: () => {
            refetchStrand();
            toast(serviceSuccessMessage('Strand updated successfully'));
            navigate(baseStrandUrl, { replace: true });
        },
        onError: (error) => {
            toast(ServiceErrorMessage(JSON.stringify(error)));
        }
    });
    const onRecordUpdate = async (
        selectedMasterCourseId: string,
        selectedMasterCourseVersion: number,
        selectedNodeDatum: TreeSectionChildNodeDto,
        titleAbbreviation: string,
        selectedMasterCourseName: string | undefined
    ) => {
        validationSchema
            .validate({
                title: selectedNodeDatum?.name,
                nodeId: selectedNodeDatum?.id,
                titleAbbreviation,
                masterCourseId: selectedMasterCourseId,
                masterCourseName: selectedMasterCourseName,
                masterCourseVersion: selectedMasterCourseVersion
            })
            .then(async (validated) => {
                updateStrand({
                    id,
                    ...validated
                });
            })
            .catch((err: unknown) => {
                if (typeof err === 'object' && err !== null) {
                    toast(utility.createValidationResponseObject(err.toString()));
                }
            });
    };

    return <LoadingWrapper loading={isLoadingStrand}>{strand && <StrandForm onAction={onRecordUpdate} strand={strand} header={'Edit Strand'} />}</LoadingWrapper>;
};

export default StrandEdit;
