import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { addOutcomeCodeUrl, baseOutcomeCodeUrl } from '../../common/constants';
import DefaultCourse from '../default-course/default-course';
import StrandEdit from '../strand/strand.edit';

const CatUtility = React.lazy(() => import('../cat-utility/cat-utility'));
const CatStatistics = React.lazy(() => import('../cat-question-stats/cat-question-stats'));
const CatContentValidator = React.lazy(() => import('../cat-content-validator/cat-content-validator'));
const CatSearch = React.lazy(() => import('../cat-question-search/cat-question-search'));
const CatSnapshots = React.lazy(() => import('../cat-snapshots/cat-snapshot'));
const ContentItemWithCourseCreate = React.lazy(() => import('../content-items/content-item-with-course.create'));
const ContentItemWithQuestionSetCreate = React.lazy(() => import('../content-items/content-item-with-question-set.create'));
const ContentItemWithUnitOfWorkCreate = React.lazy(() => import('../content-items/content-item-with-unit-of-work.create'));
const ContentItemCreate = React.lazy(() => import('../content-items/content-item.create'));
const ContentItemDetails = React.lazy(() => import('../content-items/content-item.detail'));
const ContentItemList = React.lazy(() => import('../content-items/content-item.list'));
const ContentMediaAdd = React.lazy(() => import('../content-items/content-media.add'));
const ContentMediaDetails = React.lazy(() => import('../content-items/content-media.details'));
const ContentMediaEdit = React.lazy(() => import('../content-items/content-media.edit'));
const CountriesList = React.lazy(() => import('../countries/country-list'));
const CourseContentItemLink = React.lazy(() => import('../courses/course-content-item.link'));
const CourseUnitOfWorkLink = React.lazy(() => import('../courses/course-unit-of-work-link'));
const CourseCreate = React.lazy(() => import('../courses/course.create'));
const CourseDetails = React.lazy(() => import('../courses/course.detail'));
const CourseList = React.lazy(() => import('../courses/course.list'));
const DataDictionaryEntryCreateForm = React.lazy(() => import('../data-dictionary/data-dictionary-entry.create.form'));
const DataDictionaryEntryEditForm = React.lazy(() => import('../data-dictionary/data-dictionary-entry.edit.form'));
const DataDictionaryList = React.lazy(() => import('../data-dictionary/data-dictionary.list'));
const EnvironmentCreateForm = React.lazy(() => import('../environments/environment-create-form'));
const EnvironmentEditForm = React.lazy(() => import('../environments/environment-edit-form'));
const EnvironmentList = React.lazy(() => import('../environments/environment-list'));
const LocaleList = React.lazy(() => import('../locales/locale-list'));
const NotFound = React.lazy(() => import('../not-found/not-found'));
const QuestionSetContentItemLink = React.lazy(() => import('../question-sets/question-set-linked-content-items'));
const QuestionSetQuestionLinkedQuestions = React.lazy(() => import('../question-sets/question-set-linked-questions'));
const QuestionSetWithUnitOfWorkCreate = React.lazy(() => import('../question-sets/question-set-with-unit-of-work.create'));
const QuestionSetCreateForm = React.lazy(() => import('../question-sets/question-set.create.form'));
const QuestionSetDetails = React.lazy(() => import('../question-sets/question-set.details'));
const QuestionSetList = React.lazy(() => import('../question-sets/question-set.list'));
const QuestionVariantAddForm = React.lazy(() => import('../questions/question-variant-create.form'));
const QuestionVariantEditForm = React.lazy(() => import('../questions/question-variant-edit.form'));
const QuestionVariantDetails = React.lazy(() => import('../questions/question-variant.detail'));
const QuestionWithQuestionSetCreate = React.lazy(() => import('../questions/question-with-question-set.create'));
const QuestionCreateForm = React.lazy(() => import('../questions/question.create.form'));
const QuestionDetails = React.lazy(() => import('../questions/question.detail'));
const QuestionEditForm = React.lazy(() => import('../questions/question.edit'));
const QuestionList = React.lazy(() => import('../questions/question.list'));
const StrandList = React.lazy(() => import('../strand/strand-list'));
const AddStrand = React.lazy(() => import('../strand/strand.create'));
const OutcomeCodeList = React.lazy(() => import('../outcome-codes/outcome-code.list'));
const AddOutcomeCode = React.lazy(() => import('../outcome-codes/outcome-code.create'));
const EditOutcomeCode = React.lazy(() => import('../outcome-codes/outcome-code.edit'));
const UnitOfWorkContentItemLink = React.lazy(() => import('../units-of-work/unit-of-work-content-item.link'));
const UnitOfWorkQuestionSetLink = React.lazy(() => import('../units-of-work/unit-of-work-question-set.link'));
const UnitOfWorkInCourseCreate = React.lazy(() => import('../units-of-work/unit-of-work-in-course.create'));
const UnitOfWorkCreateForm = React.lazy(() => import('../units-of-work/unit-of-work.create'));
const UnitOfWorkDetails = React.lazy(() => import('../units-of-work/unit-of-work.details'));
const UnitOfWorkList = React.lazy(() => import('../units-of-work/units-of-work.list'));
const HomeDashboard = React.lazy(() => import('../dashboard/home-dashboard'));
const LoginCredential = React.lazy(() => import('../login-credential/login-credential'));
const RouteList: React.FC = () => (
    <Routes>
        <Route path={'/'} element={<HomeDashboard header={'Home'} />} />

        <Route path={'/default-course'} element={<DefaultCourse />} />

        <Route path={'/courses'} element={<CourseList header={'Courses'} />} />
        <Route path={'/courses/add'} element={<CourseCreate header={'Create Course'} />} />
        <Route path={'/courses/:id'} element={<CourseDetails header={'Course Details'} />} />

        <Route path={'/courses/:parentId/units-of-work/add'} element={<UnitOfWorkInCourseCreate />} />
        <Route path={'/courses/:parentId/units-of-work/link/:sortOrderId'} element={<CourseUnitOfWorkLink />} />
        <Route path={'/courses/:parentId/content-items/link'} element={<CourseContentItemLink header={'Add Content Item For This Course'} />} />

        <Route path={'/strand'} element={<StrandList />} />
        <Route path={'/strand/add'} element={<AddStrand />} />
        <Route path={'/strand/edit/:id'} element={<StrandEdit />} />

        <Route path={baseOutcomeCodeUrl} element={<OutcomeCodeList />} />
        <Route path={addOutcomeCodeUrl} element={<AddOutcomeCode />} />
        <Route path={`${baseOutcomeCodeUrl}/edit/:id`} element={<EditOutcomeCode />} />

        <Route path={'/units-of-work'} element={<UnitOfWorkList header={'Units Of Work'} />} />
        <Route path={'/units-of-work/:id'} element={<UnitOfWorkDetails header={'Unit Of Work Details'} />} />
        <Route path={'/units-of-work/add'} element={<UnitOfWorkCreateForm header={'Create Unit Of Work'} />} />

        <Route path={'/units-of-work/:parentId/question-sets/add/:sortOrderId'} element={<QuestionSetWithUnitOfWorkCreate header={'Create Question Set For Unit Of Work'} />} />
        <Route path={'/units-of-work/:parentId/question-sets/link/:sortOrderId'} element={<UnitOfWorkQuestionSetLink />} />
        <Route path={'/units-of-work/:parentId/content-items/link'} element={<UnitOfWorkContentItemLink />} />

        <Route path={'/question-sets'} element={<QuestionSetList />} />
        <Route path={'/question-sets/:id'} element={<QuestionSetDetails header={'Question Set Details'} />} />
        <Route path={'/question-sets/add'} element={<QuestionSetCreateForm header={'Create Question Set'} />} />

        <Route
            path={'/questions/:parentId/questions/add/:sortOrderId/type/:questionSetType'}
            element={<QuestionWithQuestionSetCreate header={'Create Question For Question Set'} />}
        />
        <Route path={'/question-sets/:parentId/questions/link/:sortOrderId'} element={<QuestionSetQuestionLinkedQuestions header={'Add Question(s) To Question Set'} />} />
        <Route path={'/question-sets/:parentId/content-items/link'} element={<QuestionSetContentItemLink header={'Add Content Item To Question Set'} />} />

        <Route path={'/questions'} element={<QuestionList header={'Questions'} />} />
        <Route path={'/questions/add'} element={<QuestionCreateForm header={'Create Question'} />} />
        <Route path={'/cat-questions/edit/:id'} element={<QuestionEditForm displayLegacyQuestions header={'Edit Question CAT'} />} />
        <Route path={'/questions/edit/:id'} element={<QuestionEditForm header={'Edit Question'} />} />
        <Route path={'/questions/:id'} element={<QuestionDetails header={'Question Details'} />} />
        <Route path={'/questions/:parentId/variants/:id'} element={<QuestionVariantDetails />} />
        <Route path={'/questions/:parentId/variants/add/:sortOrderId'} element={<QuestionVariantAddForm />} />
        <Route path={'/questions/:parentId/variants/edit/:id'} element={<QuestionVariantEditForm />} />

        <Route path={'/cat-questions'} element={<QuestionList displayLegacyQuestions header={'CAT Questions'} />} />
        <Route path={'/cat-snapshots'} element={<CatSnapshots header={'CAT Snapshots'} />} />
        <Route path={'/cat-questions/:id'} element={<QuestionDetails displayLegacyQuestions header={'CAT Question Details'} />} />
        <Route path={'/cat-questions/:parentId/variants/edit/:id'} element={<QuestionVariantEditForm />} />
        <Route path={'/cat-questions/:parentId/variants/:id'} element={<QuestionVariantDetails />} />

        <Route path={'/cat-statistics'} element={<CatStatistics />} />
        <Route path={'/cat-utility'} element={<CatUtility />} />
        <Route path={'/cat-question-search'} element={<CatSearch />} />
        <Route path={'/content-validator'} element={<CatContentValidator />} />

        <Route path={'/content-items'} element={<ContentItemList header={'Content Items'} />} />
        <Route path={'/content-items/add'} element={<ContentItemCreate header={'Create Content Item'} />} />
        <Route path={'/content-items/:id'} element={<ContentItemDetails header={'Content Item Details'} />} />
        <Route path={'/courses/:parentId/content-items/add'} element={<ContentItemWithCourseCreate header={'Add Content Item To Course'} />} />
        <Route path={'/units-of-work/:parentId/content-items/add'} element={<ContentItemWithUnitOfWorkCreate header={'Add Content Item For Unit Of Work'} />} />
        <Route path={'/question-sets/:parentId/content-items/add'} element={<ContentItemWithQuestionSetCreate header={'Add Content Item To Question Set'} />} />
        <Route path={'/content-items/:itemId/content-media/:id'} element={<ContentMediaDetails header={'Content Media Details'} />} />
        <Route path={'/content-items/:itemId/content-media/edit/:id'} element={<ContentMediaEdit header={'Edit Content Media'} />} />
        <Route path={'/content-items/:itemId/content-media/add'} element={<ContentMediaAdd header={'Create Content Media'} />} />

        <Route path={'/settings/countries'} element={<CountriesList />} />

        <Route path={'/settings/environments'} element={<EnvironmentList header={'Environments'} />} />
        <Route path={'/settings/environments/add'} element={<EnvironmentCreateForm header={'Add Environment'} />} />
        <Route path={'/settings/environments/:id'} element={<EnvironmentEditForm header={'Edit Environment'} />} />

        <Route path={'/settings/data-dictionary'} element={<DataDictionaryList />} />
        <Route path={'/settings/data-dictionary/add'} element={<DataDictionaryEntryCreateForm header={'Add Data Dictionary Entry'} />} />
        <Route path={'/settings/data-dictionary/:id'} element={<DataDictionaryEntryEditForm header={'Edit Data Dictionary Entry'} />} />

        <Route path={'/settings/locales'} element={<LocaleList header={'Locales'} />} />

        <Route path={'/settings/login-credential'} element={<LoginCredential />} />

        <Route element={<NotFound />} path={'*'} />
    </Routes>
);

export default RouteList;
