import { Box, FormControl, FormHelperText, HStack, Tag, TagCloseButton, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';

import { ITag } from '../../tags/interfaces/tag-props.interface';

interface IFilterTagListDisplay {
    tags: ITag[];
    editMode: boolean;
    editAction: (tagKey: string) => void;
}

const FilterTagListDetails: React.FC<IFilterTagListDisplay> = ({ editMode, tags, editAction }) => {
    const tagDetails = () => {
        const tagRender = tags?.map((tag, key) => (
            <WrapItem key={key}>
                <Tag size={'lg'} borderRadius="full" colorScheme="teal">
                    <Text fontWeight={'bold'}>{tag.tagType}</Text> : {tag.value}
                    {editMode && <TagCloseButton onClick={() => editAction(tag.value)} />}
                </Tag>
            </WrapItem>
        ));

        return tagRender;
    };

    return (
        <FormControl display="flex" justifyContent="center">
            {tags.length > 0 ? (
                <Box p={1}>
                    <HStack>
                        <Wrap>{tagDetails().map((item) => item)}</Wrap>
                    </HStack>
                </Box>
            ) : (
                <FormHelperText color={'red.500'}>No available tags to display</FormHelperText>
            )}
        </FormControl>
    );
};

export default FilterTagListDetails;
