import { Flex, Link, Menu, MenuButton, Text } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link as RouteLink } from 'react-router-dom';

interface NavbarItemProps {
    title: string;
    icon: IconDefinition;
    routeTo: string;
    active?: boolean;
}

const NavbarItem: React.FC<NavbarItemProps> = ({ routeTo, active, icon, title }) => (
    <Flex mt={2} flexDir={'column'} w={'202px'} alignItems={'flex-start'}>
        <Menu placement={'right'}>
            <Link
                data-testid={'navbar-item'}
                as={RouteLink}
                to={routeTo}
                backgroundColor={active ? 'gray.200' : '#FFFFFF'}
                p={3}
                borderRadius={8}
                _hover={{
                    textDecor: 'none',
                    backgroundColor: 'gray.200'
                }}
                w={'100%'}
            >
                <MenuButton data-testid={'menu-button'} w={'100%'}>
                    <Flex>
                        <FontAwesomeIcon icon={icon} fontSize={'xl'} color={active ? '#FFFFFF' : 'gray.500'} />
                        <Text ml={5}>{title}</Text>
                    </Flex>
                </MenuButton>
            </Link>
        </Menu>
    </Flex>
);

export default NavbarItem;
