import CourseDeliveryService from './api/course.delivery.service';
import CourseService from './api/courses.service';
import { ICourseSearch, ICoursesSearch } from './interfaces/course-search-args.interface';
import { IAddContentItemsToCourse } from './interfaces/input/course.content-item.input.interface';
import { ICourseDeliveryAddInput } from './interfaces/input/course.delivery.add.input.interface';
import { AddUnitOfWorkToCourseInput, ICourseInput } from './interfaces/input/course.input.interface';
import { CourseArchiveUpdateInput, ICourseCountryInput, ICourseRegionInput, ICourseStateUpdateInput } from './interfaces/input/input';
import { RemoveChildFromParentInput } from '../../common/interfaces/types';
import { useConquestMutator } from '../../hooks/use-conquest-mutator';
import { useConquestQuery } from '../../hooks/use-conquest-query';
import { IUnitOfWorkInCourseAdd } from '../units-of-work/interfaces/unit-of-work-in-course.interface';

const courseService = new CourseService();
const courseDeliveryService = new CourseDeliveryService();

export const getAuthoredCoursesKey = 'getAuthoredCourses';

export const getAuthoredCourseKey = 'getAuthoredCourse';

export const getDeliveredCoursesKey = 'getDeliveredCourses';

export const getCourseDeliverKey = 'getCourseDeliver';

export const useGetAuthoredCourses = () => {
    return useConquestQuery(async (searchArgs: ICoursesSearch) => {
        const { data } = await courseService.getCourses(searchArgs);

        return data?.getCourses;
    }, getAuthoredCoursesKey);
};

export const useAddCourse = () => {
    return useConquestMutator(async (course: ICourseInput) => {
        const { data } = await courseService.addCourse(course);

        return data?.addCourse;
    });
};

export const useAddContentItemsToCourse = () => {
    return useConquestMutator(async (course: IAddContentItemsToCourse) => {
        const { data } = await courseService.addContentItemsToCourse(course);

        return data?.addContentItemsToCourse;
    });
};

export const useAddCourseCountry = () => {
    return useConquestMutator(async (countries: ICourseCountryInput) => {
        const { data } = await courseService.addCourseCountries(countries);

        return data?.addCourseCountries;
    });
};

export const useRemoveCourseCountry = () => {
    return useConquestMutator(async (countries: ICourseCountryInput) => {
        const { data } = await courseService.removeCourseCountries(countries);

        return data?.removeCourseCountries;
    });
};

export const useUpdateCourseState = () => {
    return useConquestMutator(async (state: ICourseStateUpdateInput) => {
        const { data } = await courseService.updateCourseState(state);

        return data?.updateCourseState;
    });
};

export const useRemoveCourse = () => {
    return useConquestMutator(async (id: string) => {
        const { data } = await courseService.removeCourse(id);

        return data?.removeCourse;
    });
};

export const useUpdateUnitsOfWorkSortOrderInCourse = () => {
    return useConquestMutator(async (input: IUnitOfWorkInCourseAdd[]) => {
        const { data } = await courseService.updateUnitsOfWorkSortOrderInCourse(input);

        return data?.updateUnitsOfWorkSortOrderInCourse;
    });
};

export const useGetAuthoredCourse = () => {
    return useConquestQuery(async (searchArgs: ICourseSearch) => {
        const { data } = await courseService.getCourse(searchArgs);

        return data?.getCourses.courses[0];
    }, getAuthoredCourseKey);
};

export const useRemoveContentItemsFromCourse = () => {
    return useConquestMutator(async (input: RemoveChildFromParentInput) => {
        const { data } = await courseService.removeContentItemsFromCourse(input);

        return data?.removeContentItemsFromCourse;
    });
};

export const useRemoveRegions = () => {
    return useConquestMutator(async (regions: ICourseRegionInput) => {
        const { data } = await courseService.removeCourseRegions(regions);

        return data?.removeCourseRegions;
    });
};

export const useAddCourseRegions = () => {
    return useConquestMutator(async (regions: ICourseRegionInput) => {
        const { data } = await courseService.addCourseRegions(regions);

        return data?.addCourseRegions;
    });
};

export const useAddUnitsOfWorkToCourse = () => {
    return useConquestMutator(async (input: AddUnitOfWorkToCourseInput) => {
        const { data } = await courseService.addUnitsOfWorkToCourse(input);

        return data?.addUnitsOfWorkToCourse;
    });
};

export const useUpdateCourseArchiveStatusDeliver = () => {
    return useConquestMutator(async (input: CourseArchiveUpdateInput) => {
        const { data } = await courseDeliveryService.updateCourseArchiveStatusDeliver(input);

        return data?.updateCourseArchiveStatusDeliver;
    });
};

export const useAddCourseDeliver = () => {
    return useConquestMutator(async (input: ICourseDeliveryAddInput) => {
        const { data } = await courseDeliveryService.addCourseDeliver(input);

        return data?.addCourseDeliver;
    });
};

export const useGetDeliveredCourses = () => {
    return useConquestQuery(async () => {
        const { data } = await courseDeliveryService.getCourses();

        return data?.getCourseDeliver;
    }, getDeliveredCoursesKey);
};

export const useGetCourseByIdsDeliver = () => {
    return useConquestQuery(async (ids: string[]) => {
        const { data } = await courseDeliveryService.getCourseByIds(ids);

        return data?.getCoursesByIdsDeliver;
    }, getCourseDeliverKey);
};
