import { Flex, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Tree } from 'react-d3-tree';
import { useMutation } from 'react-query';

import CurriculumMasterCourseSelector from './curriculum-master-course-selector';
import StrandCountrySelector from './strand-country-selector';
import ForeignObjectNode from './strand-node';
import StrandParentNodeDropdown from './strand-parent-node-dropdown';
import { StrandDto } from './strand.interface';
import { baseStrandUrl } from '../../common/constants';
import ServiceErrorMessage from '../../common/toast-messages/service-error-message';
import serviceSuccessMessage from '../../common/toast-messages/service-success-message';
import { useCenteredTree } from '../../hooks/use-centred-tree';
import AccordionComponent from '../base/accordion/accordion';
import FormFooter from '../base/form-footer';
import InputTextField from '../base/input-text.field';
import ContentBase from '../base/layout/content.base';
import { useGetMasterCourseHierarchy, useGetMasterCourses } from '../curriculum/api/curriculum.service.helper';
import { SectionChildNodeDto, TreeSectionChildNodeDto } from '../curriculum/interface';

interface StrandFormProps {
    onAction: (
        selectedMasterCourseId: string,
        selectedMasterCourseVersion: number,
        selectedNodeDatum: TreeSectionChildNodeDto,
        titleAbbreviation: string,
        selectedMasterCourseName: string | undefined
    ) => void;
    header: string;
    strand?: StrandDto;
}

const StrandForm: React.FC<StrandFormProps> = ({ onAction, header, strand }) => {
    const [selectedMasterCourseId, setSelectedMasterCourseId] = useState<string | undefined>(strand?.masterCourseId);
    const [selectedMasterCourseName, setSelectedMasterCourseName] = useState<string | undefined>(strand?.masterCourseName);
    const [selectedMasterCourseVersion, setSelectedMasterCourseVersion] = useState<number | undefined>(strand?.masterCourseVersion);
    const [selectedNodeDatum, setSelectedNodeDatum] = useState<TreeSectionChildNodeDto>();
    const [strandAbbreviation, setStrandAbbreviation] = useState(selectedNodeDatum?.code || strand?.titleAbbreviation);
    const [selectedParentNode, setSelectedParentNode] = useState<SectionChildNodeDto[]>();
    const { translate, containerRef } = useCenteredTree();
    const toast = useToast();
    const {
        mutate: getMasterCourses,
        data: masterCourseList,
        isLoading: isMasterCoursesLoading
    } = useMutation(useGetMasterCourses(), {
        onSuccess: () => {
            toast(serviceSuccessMessage('Master courses fetched successfully'));
        },
        onError: (error) => {
            toast(ServiceErrorMessage(JSON.stringify(error)));
        }
    });
    const {
        mutate: getMasterCourseHierarchy,
        data: masterCourseHierarchy,
        isLoading: isSectionsLoading
    } = useMutation(useGetMasterCourseHierarchy(), {
        onSuccess: () => {
            toast(serviceSuccessMessage('Master course hierarchy fetched successfully'));
        },
        onError: (error) => {
            toast(ServiceErrorMessage(JSON.stringify(error)));
        }
    });

    return (
        <ContentBase header={header}>
            <StrandCountrySelector isMasterCoursesLoading={isMasterCoursesLoading} getMasterCourses={getMasterCourses} />
            <CurriculumMasterCourseSelector
                getMasterCourseHierarchy={getMasterCourseHierarchy}
                isSectionsLoading={isSectionsLoading}
                masterCourseList={masterCourseList}
                masterCourseHierarchy={masterCourseHierarchy}
                selectedMasterCourseId={selectedMasterCourseId}
                setSelectedMasterCourseId={setSelectedMasterCourseId}
                setSelectedMasterCourseVersion={setSelectedMasterCourseVersion}
                setSelectedMasterCourseName={setSelectedMasterCourseName}
                selectedMasterCourseVersion={selectedMasterCourseVersion}
            />
            <StrandParentNodeDropdown masterCourseHierarchy={masterCourseHierarchy} selectedMasterCourseId={selectedMasterCourseId} setSelectedParentNode={setSelectedParentNode} />
            <AccordionComponent isClosedOnRender={!!masterCourseHierarchy} title={'4. Select a publication section node'}>
                {selectedParentNode ? (
                    <Flex ref={containerRef} height={1500}>
                        <Tree
                            renderCustomNodeElement={(treeProps) => (
                                <ForeignObjectNode
                                    setStrandAbbreviation={setStrandAbbreviation}
                                    nodeDatum={treeProps.nodeDatum}
                                    toggleNode={treeProps.toggleNode}
                                    selectedNodeDatum={selectedNodeDatum}
                                    setSelectedNodeDatum={setSelectedNodeDatum}
                                />
                            )}
                            onNodeClick={(e) => setSelectedNodeDatum(e as any)}
                            translate={translate}
                            orientation={'vertical'}
                            pathFunc={'step'}
                            separation={{ siblings: 2, nonSiblings: 2 }}
                            data={selectedParentNode as TreeSectionChildNodeDto[]}
                        />
                    </Flex>
                ) : (
                    <div>No sections found</div>
                )}
            </AccordionComponent>
            <AccordionComponent title={'5. Check your selection'}>
                <div>Strand title: {selectedNodeDatum?.name || strand?.title}</div>
                <Flex>
                    Strand abbreviation:
                    <InputTextField
                        helperText={
                            'This can be manually overridden. The strand abbreviation must be a maximum of two characters, composed exclusively of uppercase letters and digits, with no special characters allowed.'
                        }
                        onChange={(e) => setStrandAbbreviation(e.target.value)}
                        value={strandAbbreviation}
                        id={'strand-abbreviation'}
                        title={''}
                        isRequired={false}
                    />
                </Flex>
                <div>Node ID: {selectedNodeDatum?.id || strand?.nodeId}</div>
                <div>Master Course ID: {selectedMasterCourseId}</div>
                <div>Master Course Name: {selectedMasterCourseName}</div>
                <div>Master Course Version: {selectedMasterCourseVersion}</div>
            </AccordionComponent>
            <FormFooter
                cancelButtonRoute={baseStrandUrl}
                onRecordAdd={() =>
                    selectedMasterCourseVersion &&
                    selectedMasterCourseId &&
                    onAction(selectedMasterCourseId, selectedMasterCourseVersion, selectedNodeDatum, strandAbbreviation, selectedMasterCourseName)
                }
            />
        </ContentBase>
    );
};

export default StrandForm;
