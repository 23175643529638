import { gql } from '@apollo/client';

export class DataDictionaryUpdateQueries {
    static readonly updateDataDictionaryEntry = gql`
        mutation updateDataDictionaryEntryContent($entry: DataDictionaryEntryInput!) {
            updateDataDictionaryEntryContent(entry: $entry) {
                category
                id
                sortOrder
                value
            }
        }
    `;
}
