import { FetchResult } from '@apollo/client';

import { DataDictionaryCreateQueries } from './queries/data-dictionary.create.queries';
import { DataDictionaryDeleteQueries } from './queries/data-dictionary.delete.queries';
import { DataDictionaryGetQueries } from './queries/data-dictionary.get.queries';
import { DataDictionaryUpdateQueries } from './queries/data-dictionary.update.queries';
import BaseApiService from '../../../api/base.service';
import { IDataDictionaryEntry } from '../interfaces/data-dictionary-entry.interface';
import { DataDictionaryEntryInput } from '../interfaces/data-dictionary-input.interface';
import { IDataDictionaryListState } from '../interfaces/data-dictionary-list.interface';

export default class DataDictionaryService extends BaseApiService {
    constructor() {
        super(window._env_.GATEWAY_API_URL);
    }

    public async getDataDictionaryEntries(page: number, limit: number): Promise<FetchResult<{ getAllDataDictionaryEntriesContent: IDataDictionaryListState }>> {
        return this.graphqlClient.query({
            query: DataDictionaryGetQueries.getAllDataDictionaryEntries,
            variables: {
                limit,
                page
            }
        });
    }

    public async getDataDictionaryEntriesByCategory(
        category: string,
        page: number,
        limit: number
    ): Promise<FetchResult<{ getDataDictionaryEntriesByCategoryContent: IDataDictionaryListState }>> {
        return this.graphqlClient.query({
            query: DataDictionaryGetQueries.getDataDictionaryEntriesByCategory,
            variables: {
                category,
                page,
                limit
            }
        });
    }

    public async getDataDictionaryEntry(entryId: number): Promise<FetchResult<{ getDataDictionaryEntry: IDataDictionaryEntry }>> {
        return this.graphqlClient.query({
            query: DataDictionaryGetQueries.getDataDictionaryEntry,
            variables: { entryId }
        });
    }

    public async createDataDictionaryEntry(entry: DataDictionaryEntryInput): Promise<FetchResult<{ createDataDictionaryEntryContent: IDataDictionaryEntry }>> {
        return this.graphqlClient.mutate({
            mutation: DataDictionaryCreateQueries.createDataDictionaryEntry,
            variables: { entry }
        });
    }

    public async updateDataDictionaryEntry(entry: DataDictionaryEntryInput): Promise<FetchResult<{ updateDataDictionaryEntryContent: IDataDictionaryEntry }>> {
        return this.graphqlClient.mutate({
            mutation: DataDictionaryUpdateQueries.updateDataDictionaryEntry,
            variables: { entry }
        });
    }

    public async deleteDataDictionaryEntry(entry: DataDictionaryEntryInput): Promise<FetchResult<{ deleteDataDictionaryEntryContent: boolean }>> {
        return this.graphqlClient.mutate({
            mutation: DataDictionaryDeleteQueries.deleteDataDictionaryEntry,
            variables: {
                entry
            }
        });
    }
}
