import { defaultTheme as questionComponentsTheme } from '@3plearning/question-components-library';
import { extendTheme, StyleFunctionProps, ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false
};

let colour: string;

switch (window._env_?.ENVIRONMENT) {
    case 'prod':
        colour = '#9bdb95';
        break;
    case 'qa':
        colour = '#fffca3';
        break;
    default:
        colour = '#eef5f3';
}

const defaultTheme = extendTheme(
    {
        config,
        styles: {
            global: (props: StyleFunctionProps) => ({
                body: {
                    '#mathlive-suggestion-popover': {
                        zIndex: 1500
                    },
                    '.ML__keyboard.is-visible': {
                        zIndex: 2000
                    },
                    overflowX: 'hidden',
                    background: mode(colour, 'black')(props),
                    '.mathlive-native-ui .ML__keyboard': {
                        height: 'var(--_keyboard-height)'
                    },
                    '.mathlive-native-ui': {
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        zIndex: 2000
                    }
                }
            })
        }
    },
    questionComponentsTheme,
    {
        components: {
            Button: {
                baseStyle: {
                    borderRadius: 'md'
                }
            }
        }
    },
    {
        fonts: {
            heading: 'sofia-pro',
            body: 'sofia-pro'
        }
    }
);

export default defaultTheme;
