import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import authenticationService from '../services/authentication.service';

export default class BaseApiService {
    protected readonly graphqlClient: ApolloClient<NormalizedCacheObject>;

    constructor(baseUrl: string) {
        const authLink = setContext(async (_, { headers }) => {
            const token = authenticationService.isTokenExpired() ? await authenticationService.updateToken() : authenticationService.getToken();

            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : ''
                }
            };
        });
        const httpLink = createHttpLink({ uri: `${baseUrl}/graphql` });
        const defaultOptions: DefaultOptions = {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore'
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            }
        };

        this.graphqlClient = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({ addTypename: false }),
            defaultOptions
        });
    }
}
