import { Flex, HStack, StackDivider } from '@chakra-ui/react';
import { faMagnifyingGlassArrowRight } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';

import FilterTagListDetails from './filter-tag-list-display';
import AddTagFilter from './tag-filter.add';
import { ITag } from '../../tags/interfaces/tag-props.interface';
import FaIconButton from '../../tiptap/tiptap-hotspot/font-awesome-icon-button';

interface ITagFilter {
    existingTags: ITag[];
    setFilterTags: (filterTags: ITag[]) => void;
    applyFilter: () => Promise<void>;
}

const TagFilter: React.FC<ITagFilter> = ({ existingTags, setFilterTags, applyFilter }) => {
    const addTag = async (tag: ITag) => {
        setFilterTags([...existingTags, tag]);
    };
    const removeTag = async (tagKey: string) => {
        setFilterTags(existingTags.filter((tag) => tag.value !== tagKey));
    };

    return (
        <HStack gap={2} align="center" divider={<StackDivider />} justify="center">
            <Flex>
                <AddTagFilter header={'Add a Tag'} addAction={addTag} />
            </Flex>
            <Flex>
                <FilterTagListDetails tags={existingTags} editMode={true} editAction={(tagKey: string) => removeTag(tagKey)} />
            </Flex>
            <FaIconButton icon={faMagnifyingGlassArrowRight} onClick={applyFilter} tooltipLabel="Apply filter" color="gray" cursor="pointer" />
        </HStack>
    );
};

export default TagFilter;
